import React from 'react';

interface IProps {
  isFixed: boolean;
}

export default function Footer({isFixed}: IProps) {
  return (
    <div className={`footer ${isFixed ? 'fixed' : ''}`}>
      <div>
        Copyright © {new Date().getFullYear()} Evolve Mixed Martial Arts. All Rights Reserved.{' '}
        <a
          className="text-reset"
          target="_blank"
          href="https://evolve-mma.com/privacy-policy/"
          rel="noopener noreferrer">
          Privacy Policy.
        </a>
      </div>
    </div>
  );
}
