import React from 'react';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function FetchSpinner() {
  return (
    <div className={'p-2 text-center'}>
      <FontAwesomeIcon icon={faSpinner} pulse className="mx-2" />
    </div>
  );
}
