//utils
import axios from 'axios';

export default async function resetPasswordRequest(email: string): Promise<boolean> {
  const bodyParameters = {
    email,
  };

  await axios.post('/sessions/reset-password-request', bodyParameters);
  return true;
}
