//utils
import axios from 'axios';
//types
import {IAuthResponse} from 'store/auth/types';

export default async function login(
  email: string,
  password: string,
  forceEmailMFA: boolean,
  rememberMe: boolean,
  verificationCode?: string
): Promise<IAuthResponse | string> {
  const bodyParameters = {
    login: email,
    password,
    forceEmailMFA,
    verificationCode,
    rememberMe,
  };

  const response = await axios.post('/sessions', bodyParameters);
  const {token, memberships, mfaMethod} = response.data;

  if (mfaMethod) {
    return mfaMethod;
  }

  return {token, memberships};
}
