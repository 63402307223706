import {getCssProperties} from './getCssProperties';
import formatMembershipsToLocalStorage from './formatMembershipsToLocalStorage';
import isFESArea from './isFESArea';
import isHIITArea from './isHIITArea';
import isOpenGymLevel from './isOpenGymLevel';
import isZoomArea from './isZoomArea';
import numberToDayTime from './numberToDayTime';
import setDocumentTitle from './setDocumentTitle';
import {getCalendarSlots} from './getCalendarSlots';
import {getTokenData} from './getTokenData';
import {useIsMountedRef} from './useIsMountedRef';
import {getIsShowReminders} from './getIsShowReminders';
import {organiseDayEventsByColumns} from './organiseDayEventsByColumns';

const utils = {
  getCssProperties,
  formatMembershipsToLocalStorage,
  isFESArea,
  isHIITArea,
  isOpenGymLevel,
  isZoomArea,
  numberToDayTime,
  setDocumentTitle,
  getCalendarSlots,
  getTokenData,
  useIsMountedRef,
  getIsShowReminders,
  organiseDayEventsByColumns,
};

export default utils;
