//utils
import axios from 'axios';
//types
import ActionType from 'store/actionTypes';

const getList =
  (params: {memberId: string; date: string; view: string}): any =>
  async (dispatch: any) => {
    const response = await axios.get('/get-private-classes', {params});
    let {privateClasses, date} = response.data;

    if (!privateClasses) {
      privateClasses = response.data;
    }

    dispatch({type: ActionType.GET_PRIVATE_EVENTS_SUCCESS, data: {list: privateClasses, date}});

    return privateClasses;
  };

export default getList;
