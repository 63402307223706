import ActionType from 'store/actionTypes';
import {IState, IAction} from './types';

const initialState: IState = {
  list: [],
  date: '',
};

export default function eventsReducer(state: IState = initialState, action: IAction) {
  switch (action.type) {
    case ActionType.GET_EVENTS_SUCCESS:
      return {
        ...state,
        list: action.data?.list,
        timeToNextBookingFrame: action.data?.timeToNextBookingFrame,
        date: action.data?.date,
      };

    case ActionType.REPLACE_EVENT:
      if (!action.data?.updatedEvent) {
        return state;
      }

      const {updatedEvent} = action.data;
      return {
        ...state,
        list: [
          ...state.list.filter((e) => !(e.date === updatedEvent.date && e.event.id === updatedEvent.event.id)),
          {...updatedEvent},
        ],
      };

    case ActionType.AUTH_SIGN_OUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
