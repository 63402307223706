import {combineReducers} from 'redux';
import auth from './auth/reducer';
import status from './status/reducer';
import memberships from './memberships/reducer';
import events from './events/reducer';
import privateEvents from './privateEvents/reducer';
import attendances from './attendances/reducer';

export default combineReducers({
  auth,
  status,
  memberships,
  events,
  privateEvents,
  attendances,
});
