import ActionType from 'store/actionTypes';
import {IState, IAction} from './types';

export default function statusReducer(state: IState, action: IAction) {
  switch (action.type) {
    case ActionType.UPDATE_STATUS:
      return {
        ...action.data,
      };

    default:
      return state || {};
  }
}
