import {useEffect, useState} from 'react';
import axios from 'axios';
import {LocalStorageEnum} from 'enums';
import moment from 'moment';
import IAnnouncement from 'types/announcement';

export const useAnnouncements = () => {
  const [announcements, setAnnouncements] = useState<IAnnouncement[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const localAnnouncements = localStorage.getItem(LocalStorageEnum.ANNOUNCEMENTS);
    const announcementsDateTime = localStorage.getItem(LocalStorageEnum.ANNOUNCEMENTS_DATE_TIME_REQUEST);
    const isTimeToRequestPasses = moment().diff(moment(announcementsDateTime), 'minutes') > 10;

    if (announcements.length === 0 && localAnnouncements && !isTimeToRequestPasses) {
      setAnnouncements(JSON.parse(localAnnouncements) as IAnnouncement[]);
    } else if ((!announcementsDateTime && announcements.length === 0) || isTimeToRequestPasses) {
      getAnnouncements().then((announcements) => {
        setAnnouncements(announcements);

        localStorage.setItem(LocalStorageEnum.ANNOUNCEMENTS, JSON.stringify(announcements));
        localStorage.setItem(LocalStorageEnum.ANNOUNCEMENTS_DATE_TIME_REQUEST, moment().format('YYYY-MM-DD HH:mm:ss'));
      });
    }
  });

  return announcements;
};

const getAnnouncements = async () => {
  const response = await axios.get('/announcements');

  if (response.status === 200) {
    const announcements = response.data.map((announcement: IAnnouncement, index: number) => {
      return {
        id: announcement.id,
        title: announcement.title,
        content: announcement.content,
        programs: announcement.programs,
        urlIndex: index + 1,
      };
    });

    return announcements as IAnnouncement[];
  }

  return [];
};
