//utils
import axios from 'axios';
//types
import ActionType from 'store/actionTypes';
import {IAttendance} from '../types';
import {AnyAction} from 'redux';
type DispatchType = (args: AnyAction) => AnyAction;

export const getList = async ({
  limit,
  offset,
  memberId,
  dispatch,
}: {
  limit: number;
  offset: number;
  memberId: string;
  dispatch: DispatchType;
}) => {
  const response = await axios.get('/get-attendances', {params: {memberId, limit, offset}});

  const {attendances = []}: {attendances: IAttendance[]} = response.data;
  dispatch({type: ActionType.GET_ATTENDANCES_SUCCESS, data: {list: attendances}});
  return attendances;
};
