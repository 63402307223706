export enum StatusType {
  SUCCESS = 1,
  WARNING = 2,
  ERROR = 3,
}

export interface IState {
  message: string;
  type: StatusType;
}

export interface IAction {
  data?: IState;
  type: string;
}
