import React from 'react';
//router
import {useNavigate} from 'react-router-dom';
import IAnnouncement from 'types/announcement';

interface IAnnounceProps {
  children: React.ReactNode;
  announcements: Array<IAnnouncement>;
}

export default function Announce(props: Readonly<IAnnounceProps>) {
  const navigate = useNavigate();

  return (
    <>
      {props.announcements.length > 0 && (
        <div className={`announce-bar announce-bar-${props.announcements.length}`}>
          <div className="announcement">
            {props.announcements.map((a) => (
              <div key={a.id} onClick={() => navigate(`/announcement/${a.urlIndex}`)} className="text-center">
                {a.title}
              </div>
            ))}
          </div>
        </div>
      )}
      {props.children}
    </>
  );
}
