//utils
import axios from 'axios';
import utils from 'utils';
//types
import ActionType from 'store/actionTypes';
import {LocalStorageEnum} from 'enums';

const getList =
  (force?: boolean): any =>
  async (dispatch: any) => {
    if (!force) {
      const memberships = localStorage.getItem(LocalStorageEnum.MEMBERSHIPS);

      if (memberships) {
        const list = JSON.parse(memberships);
        dispatch({type: ActionType.GET_MEMBERSHIPS_SUCCESS, data: {list}});
        return list;
      }
    }

    const response = await axios.get('/get-memberships');
    localStorage.setItem(LocalStorageEnum.MEMBERSHIPS, utils.formatMembershipsToLocalStorage(response.data));
    dispatch({type: ActionType.GET_MEMBERSHIPS_SUCCESS, data: {list: response.data}});
    return response.data;
  };

export default getList;
