const site = {
  brand: 'EVOLVE MMA - Online Booking',
  title: 'EVOLVE MMA - Online Booking',
  description: "Online booking for Evolve MMA, Asia's #1 Mixed Martial Arts Gym",
  timeZoneOffset: '+08:00',
  locale: 'en-gb',
  zoomBookingDays: 7,
  classBookingDays: 2,
  calendarRowHeightMultiplier: 2,
  calendarTimeStep: 15,
};

export default site;
