//utils
import axios from 'axios';

export default async function resetPassword(
  memberId: string,
  password: string,
  repeatedPassword: string,
  token: string
): Promise<boolean> {
  const bodyParameters = {
    password,
    repeatedPassword,
    token,
  };

  await axios.patch(`/sessions/${memberId}/reset-password`, bodyParameters);
  return true;
}
