import ActionType from 'store/actionTypes';
import {IState, IAction} from './types';

const initialState: IState = {
  list: [],
};

export default function membershipReducer(state: IState = initialState, action: IAction) {
  switch (action.type) {
    case ActionType.GET_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        list: action.data?.list,
      };

    case ActionType.UPDATE_MEMBERSHIP_INBOX_COUNT:
      const current = state.list.find((m: any) => m.memberId === action.data?.memberId);

      if (current) {
        const listWithoutCurrent = state.list.filter((m: any) => m.id !== current.id);
        const updatedList = [{...current, inboxCount: current.inboxCount - 1}, ...listWithoutCurrent];

        return {
          ...state,
          list: updatedList,
        };
      } else {
        return state;
      }

    case ActionType.AUTH_SIGN_OUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
