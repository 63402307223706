import ActionType from 'store/actionTypes';
import {IState, IAction} from './types';

const initialState: IState = {
  list: [],
};

export default function membershipReducer(state: IState = initialState, action: IAction) {
  switch (action.type) {
    case ActionType.GET_ATTENDANCES_SUCCESS:
      return {
        ...state,
        list: action.data?.list,
      };

    case ActionType.AUTH_SIGN_OUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
