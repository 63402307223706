//utils
import axios from 'axios';
//types
import {StatusType} from 'store/status/types';
import IMemberSettings from 'types/memberSettings';
//store
import actions from 'store/actions';

const updateMemberSettings =
  (memberSettings: IMemberSettings): any =>
  async (dispatch: any) => {
    await axios
      .post('/update-member-settings', memberSettings)
      .then(() => new Promise<void>((resolve) => setTimeout(resolve, 40))); // temporary deplay 40ms for DB replication lag
    dispatch(
      actions.status.status({
        message: 'The settings have been successfully updated.',
        type: StatusType.SUCCESS,
      })
    );

    dispatch(actions.memberships.getList(true));
  };

export default updateMemberSettings;
