import ActionType from 'store/actionTypes';
import {IState, IAction} from './types';

const initialState: IState = {
  memberId: '',
  alert: '',
};

export default function authReducer(state: IState = initialState, action: IAction) {
  switch (action.type) {
    case ActionType.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        memberId: action.data?.memberId,
      };

    case ActionType.AUTH_SIGN_OUT_SUCCESS:
      return initialState;

    case ActionType.AUTH_ALERT:
      return {
        ...state,
        alert: action.data?.alert,
      };

    default:
      return state;
  }
}
