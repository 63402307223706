//core
import React from 'react';
//router
import {Link, NavLink} from 'react-router-dom';
//images
import logo from 'assets/images/logo-white.png';
//settings
import settings from 'settings/site';

export default function LoginHeader() {
  return (
    <header className="main-header">
      <div className="d-flex">
        <Link to="/" title={settings.title} className="logo">
          <img src={logo} alt={settings.brand} />
        </Link>
        <div className="flex-grow-1">
          <div className="h-100">
            <nav className="d-flex align-items-center h-100 menu-nav text-center">
              <NavLink to="/" className="d-flex h-100 align-items-center text-uppercase menu-nav-item">
                Sign In
              </NavLink>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}
