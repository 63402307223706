import {IEvent} from 'store/events/types';
import {IPrivateEvent} from 'store/privateEvents/types';
import settings from 'settings/site';

export const getCalendarSlots = (events: Array<IEvent | IPrivateEvent>, days: Array<string>) => {
  const {startTime, endTime} = events
    .filter((event) => days.includes(event.date))
    .reduce(
      (result, e) => {
        return {
          startTime: Math.min(e.event.startTime, result.startTime),
          endTime: Math.max(e.event.startTime + e.event.duration, result.endTime),
        };
      },
      {startTime: 1440, endTime: 0}
    );

  if (endTime <= startTime) {
    return [390];
  }

  const timeSlots = (endTime - startTime) / settings.calendarTimeStep;
  return Array.from(Array(timeSlots), (v, i) => startTime + i * settings.calendarTimeStep);
};
