//types
import ActionType from 'store/actionTypes';
import {IEvent} from 'store/events/types';

const replaceListItem =
  (updatedEvent: IEvent): any =>
  async (dispatch: any) => {
    dispatch({type: ActionType.REPLACE_EVENT, data: {updatedEvent}});
  };

export default replaceListItem;
