import ActionType from 'store/actionTypes';
//utils
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import {LocalStorageEnum} from 'enums';
import {IToken} from '../types';

export default function authenticate(token: string) {
  const data = jwt_decode<IToken>(token);
  if (data.exp <= Math.floor(Date.now() / 1000)) {
    localStorage.removeItem(LocalStorageEnum.AUTH_TOKEN);
    localStorage.removeItem(LocalStorageEnum.MEMBERSHIPS);
    delete axios.defaults.headers.common['Authorization'];

    return {type: ActionType.AUTH_SIGN_OUT_SUCCESS};
  }

  localStorage.setItem(LocalStorageEnum.AUTH_TOKEN, token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  return {type: ActionType.AUTH_SIGN_IN_SUCCESS, data};
}
