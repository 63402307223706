//core
import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {createRoot} from 'react-dom/client';
//css
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/stylesheets/main.scss';
//utils
import axios from 'axios';
import 'moment/locale/en-gb';
//store
import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from 'store';
import actions from 'store/actions';
import {StatusType} from 'store/status/types';
//settings
import api from './settings/api';
//router
import App from './routes';
import {LocalStorageEnum} from 'enums';

const store = createStore(rootReducer, applyMiddleware(thunk));

axios.defaults.baseURL = api.base + '/booking';

const token = localStorage.getItem(LocalStorageEnum.AUTH_TOKEN);
if (token) {
  store.dispatch(actions.auth.authenticate(token));
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(actions.status.status({message: 'Session expired.', type: StatusType.WARNING}));
      store.dispatch(actions.auth.logout());
    } else {
      const {message} = error.response ? error.response.data : error;
      store.dispatch(actions.status.status({message, type: StatusType.ERROR}));
    }

    return Promise.reject(error);
  }
);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
