import * as auth from './auth/actions';
import * as status from './status/actions';
import * as memberships from './memberships/actions';
import * as events from './events/actions';
import * as privateEvents from './privateEvents/actions';
import * as attendances from './attendances/actions';
import * as inbox from './inbox/actions';

const actions = {
  auth,
  status,
  memberships,
  events,
  privateEvents,
  attendances,
  inbox,
};

export default actions;
