//utils
import axios from 'axios';
//types
import {AnyAction} from 'redux';
import ActionType from 'store/actionTypes';
type DispatchType = (args: AnyAction) => AnyAction;

export const markAsRead = async ({id, memberId, dispatch}: {id: string; memberId: string; dispatch: DispatchType}) => {
  const response = await axios.patch(`inbox/${id}/mark-read`, {memberId});

  if (response.status === 200) {
    dispatch({type: ActionType.UPDATE_MEMBERSHIP_INBOX_COUNT, data: {memberId}});
    return true;
  }

  return false;
};
