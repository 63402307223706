import {CSSProperties} from 'react';
import {IEvent} from 'store/events/types';
import {IPrivateEvent} from '../store/privateEvents/types';
import settings from 'settings/site';

export const getCssProperties = (calendarStartTime: number, calendarEvent: IEvent | IPrivateEvent): CSSProperties => ({
  ...calendarEvent.props.style,
  top:
    ((calendarEvent.event.startTime - calendarStartTime) / settings.calendarTimeStep) *
      settings.calendarRowHeightMultiplier +
    'rem',
  height: (calendarEvent.event.duration / settings.calendarTimeStep) * settings.calendarRowHeightMultiplier + 'rem',
});
