//core
import React, {useState, lazy, Suspense, useEffect} from 'react';
//router
import {Route, Routes, Navigate, useMatch} from 'react-router-dom';
//store
import {useDispatch, useSelector} from 'react-redux';
import actions from 'store/actions';
import IStoreState from 'store/types';
import {IAuthResponse} from 'store/auth/types';
//requests
import {bookClass} from 'requests';
//components
import Spinner from 'components/Spinner';
import Status from 'components/Status';
import Footer from 'components/Footer';
import BetaLabel from 'components/BetaLabel';
import Announce from 'components/Announce';
import ScrollToTop from 'components/ScrollToTop';
import LoginHeader from './login/LoginHeader';
import ServiceWorkerWrapper from '../components/ServiceWorkerWrapper';
import Announcement from './profile/Announcement';
//utils
import utils from 'utils';
import {LocalStorageEnum} from 'enums';
import {useAnnouncements} from 'effects/useAnnouncements';

const Login = lazy(() => import('./login'));
const ResetPassword = lazy(() => import('./login/ResetPassword'));
const MemberArea = lazy(() => import('./memberArea'));

interface IAutoBook {
  memberId: string;
  eventId: string;
  date: string;
}

export default function App() {
  const dispatch = useDispatch();
  const [autoBook, setAutoBook] = useState<IAutoBook>();
  const memberId = useSelector((state: IStoreState) => state.auth.memberId);
  const memberships = useSelector((state: IStoreState) => state.memberships.list || []);
  const autoBookMatch = useMatch('/book/:memberId/:eventId/:date');
  const announcements = useAnnouncements();

  useEffect(() => {
    async function autoBookClass({eventId, date, memberId}: IAutoBook) {
      try {
        await bookClass(eventId, date, memberId, 'confirm', dispatch);
      } catch (error: any) {
        if (error?.response && error.response.status === 401) {
          setTimeout(() => setAutoBook({eventId, date, memberId}), 100);
        }
      }
    }

    if (autoBookMatch?.params) {
      const {memberId: autoBookMemberId, eventId, date} = autoBookMatch.params;

      if (autoBookMemberId && eventId && date) {
        autoBookClass({memberId: autoBookMemberId, eventId, date});
      }
    }
  }, [autoBookMatch, dispatch]);

  useEffect(() => {
    async function autoBookClass({eventId, date, memberId}: IAutoBook) {
      setAutoBook(undefined);
      await bookClass(eventId, date, memberId, 'confirm', dispatch);
    }

    if (memberId && autoBook) {
      autoBookClass(autoBook);
    }
  }, [memberId, autoBook, dispatch]);

  function onLoginSuccess(authResponse: IAuthResponse) {
    dispatch(actions.status.status());
    dispatch(actions.auth.authenticate(authResponse.token));
    localStorage.setItem(LocalStorageEnum.MEMBERSHIPS, utils.formatMembershipsToLocalStorage(authResponse.memberships));
  }

  const programs = memberships.map((m) => m.program);
  const pageAnnouncements =
    memberships.length === 0
      ? announcements.filter((a) => a.programs.length === 3)
      : announcements.filter((a) => a.programs.some((p) => programs.includes(p)));

  return (
    <>
      <ServiceWorkerWrapper />
      <Suspense fallback={<Spinner />}>
        <ScrollToTop />
        <Announce announcements={pageAnnouncements}>
          <BetaLabel />
          <Status />
          <Routes>
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            {!memberId && (
              <>
                <Route
                  path="/announcement/:id"
                  element={
                    <>
                      <LoginHeader />
                      <div className="body">
                        <Announcement announcements={pageAnnouncements} />
                      </div>
                      <Footer isFixed={false} />
                    </>
                  }
                />
                <Route path="/" element={<Login onLoginSuccess={onLoginSuccess} />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </>
            )}
            {memberId && <Route path="/*" element={<MemberArea />} />}
          </Routes>
          <Footer isFixed={!memberId} />
        </Announce>
      </Suspense>
    </>
  );
}
