//core
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import IAnnouncement from 'types/announcement';

interface IAnnouncementProps {
  announcements: IAnnouncement[];
}

export default function Announcement(props: Readonly<IAnnouncementProps>) {
  const navigate = useNavigate();

  const currAnnouncementId = +window.location.pathname.split('/')[2];
  const announcement = props?.announcements?.find((a) => a.urlIndex === currAnnouncementId);

  useEffect(() => {
    if (props.announcements?.length > 0 && !announcement) {
      navigate('/login');
    }
  }, [announcement, navigate, props?.announcements]);

  return (
    <div className="pt-4">
      <div className="text-content mb-3 py-2 px-3 container-sm">
        {announcement && <div dangerouslySetInnerHTML={{__html: announcement.content}} />}
      </div>
    </div>
  );
}
