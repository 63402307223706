import React from 'react';
//store
import {useSelector, useDispatch} from 'react-redux';
import actions from 'store/actions';
//types
import IStoreState from 'store/types';
import {StatusType} from 'store/status/types';

export default function Status() {
  const dispatch = useDispatch();
  const status = useSelector((state: IStoreState) => state.status);

  if (!status || !status.message) {
    return null;
  }

  const typeClassName = getStatusTypeClassName(status.type);

  return (
    <div className={`badge status-badge py-2 px-4 m-0 d-flex align-items-center ${typeClassName}`}>
      <div className="w-100 py-2">
        {status.message}
        <button
          type="button"
          onClick={() => dispatch(actions.status.status())}
          className="btn btn-link text-reset text-decoration-none">
          <span>&times;</span>
        </button>
      </div>
    </div>
  );
}

function getStatusTypeClassName(type: StatusType) {
  switch (type) {
    case StatusType.SUCCESS:
      return 'badge-success';
    case StatusType.WARNING:
      return 'badge-warning';
    case StatusType.ERROR:
      return 'badge-danger';
    default:
      return '';
  }
}
