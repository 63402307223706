import {IMembership} from 'store/memberships/types';

export default function formatMembershipsToLocalStorage(memberships: Array<IMembership>) {
  return JSON.stringify(
    memberships.map((membership: IMembership) => {
      return {
        ...membership,
        email: undefined,
        mobile: undefined,
      };
    })
  );
}
