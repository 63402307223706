const ActionType = {
  AUTH_SIGN_IN_SUCCESS: 'AUTH_SIGN_IN_SUCCESS',
  AUTH_SIGN_OUT_SUCCESS: 'AUTH_SIGN_OUT_SUCCESS',
  AUTH_ALERT: 'AUTH_ALERT',

  GET_MEMBERSHIPS_SUCCESS: 'GET_MEMBERSHIPS_SUCCESS',
  GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
  GET_PRIVATE_EVENTS_SUCCESS: 'GET_PRIVATE_EVENTS_SUCCESS',
  GET_ATTENDANCES_SUCCESS: 'GET_ATTENDANCES_SUCCESS',

  UPDATE_STATUS: 'UPDATE_STATUS',
  UPDATE_MEMBERSHIP_INBOX_COUNT: 'UPDATE_MEMBERSHIP_INBOX_COUNT',

  REPLACE_EVENT: 'REPLACE_EVENT',
};

export default ActionType;
