import {LocalStorageEnum} from 'enums';
import {IToken} from 'store/auth/types';
import jwt_decode from 'jwt-decode';

export function getTokenData(token?: string | null) {
  if (!token) {
    token = localStorage.getItem(LocalStorageEnum.AUTH_TOKEN);
  }

  return jwt_decode<IToken>(token || '');
}
