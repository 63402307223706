//utils
import axios from 'axios';
//types
import {StatusType} from 'store/status/types';
//store
import actions from 'store/actions';

const replaceMemberPartner =
  (eventId: string, eventDate: string, memberId: string, partnerId: string): any =>
  async (dispatch: any) => {
    const response = await axios.post('/replace-partner', {eventId, eventDate, memberId, partnerId});
    const {event, message} = response.data;

    if (message) {
      dispatch(actions.status.status({message, type: StatusType.SUCCESS}));
    }
    return {event};
  };

export default replaceMemberPartner;
