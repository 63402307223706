export const getIsShowReminders = (level: string) => {
  const MTReminderKeys = [
    'MT_LEVEL_2',
    'MT_LEVEL_3',
    'MT_SPARRING',
    'MT_LEVEL_3_SPARRING',
    'MT_LEVEL_3_PADWORK',
    'MT_COMPETITORS_PROGRAM',
  ];
  const boxingReminderKeys = ['BOXING_2_SPARRING', 'BOXING_COMPETITORS_PROGRAM'];

  return {
    isMT: MTReminderKeys.includes(level),
    isBoxing: boxingReminderKeys.includes(level),
  };
};
