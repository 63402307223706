import ActionType from 'store/actionTypes';
//utils
import axios from 'axios';
import {LocalStorageEnum} from 'enums';

export default function logout() {
  localStorage.removeItem(LocalStorageEnum.AUTH_TOKEN);
  localStorage.removeItem(LocalStorageEnum.MEMBERSHIPS);
  delete axios.defaults.headers.common['Authorization'];
  return {type: ActionType.AUTH_SIGN_OUT_SUCCESS};
}
