//settings
import settings from 'settings/site';

export default function setDocumentTitle(title?: string, metaDescription?: string): void {
  document.title = settings.title;

  if (title) {
    document.title += ` ${title}`;
  }

  if (metaDescription) {
    document.querySelector('meta[name="description"]')?.setAttribute('content', metaDescription);
  } else {
    document.querySelector('meta[name="description"]')?.setAttribute('content', settings.description);
  }
}
