//utils
import axios from 'axios';
//types
import {IInbox} from '../types';
import {AnyAction} from 'redux';
type DispatchType = (args: AnyAction) => AnyAction;

export const getList = async (memberId: string, dispatch: DispatchType) => {
  const response = await axios.get(`/inbox/${memberId}/list`);

  const {inboxList = []}: {inboxList: IInbox[]} = response.data;
  return inboxList.sort((a, b) => (a.date > b.date ? -1 : 1));
};
