import {IEvent} from 'store/events/types';

export function organiseDayEventsByColumns(events: IEvent[], renderColumn = 0) {
  if (!events.length) {
    return [];
  }

  events.sort((a, b) => a.event.startTime - b.event.startTime);

  const remainingEvents = [];
  let eventToCompare = events[0];
  for (let i = 0; i < events.length; i++) {
    if (i === 0) {
      events[i].renderColumn = renderColumn;
      continue;
    }
    const event = events[i];

    if (event.event.startTime >= eventToCompare.event.startTime + eventToCompare.event.duration) {
      event.renderColumn = renderColumn;
      eventToCompare = event;
    } else {
      remainingEvents.push(event);
    }
  }

  if (remainingEvents.length) {
    organiseDayEventsByColumns(remainingEvents, renderColumn + 1);
  }

  if (renderColumn === 0) {
    for (const event of events) {
      event.intersectionColumns = events.reduce((acc: number[], e) => {
        if (
          e.renderColumn !== undefined &&
          !acc.includes(e.renderColumn) &&
          event.renderColumn !== e.renderColumn &&
          isEventsIntersecting(e, event)
        ) {
          acc.push(e.renderColumn);
        }
        return acc;
      }, []).length;
    }

    for (const event of events) {
      events.forEach((e) => {
        if (e.renderColumn !== undefined && isEventsIntersecting(e, event)) {
          event.intersectionColumns = Math.max(event.intersectionColumns || 0, e.intersectionColumns || 0);
        }
      });
    }
  }

  return events;
}

function isEventsIntersecting(event1: IEvent, event2: IEvent) {
  return (
    event1.event.id !== event2.event.id &&
    event1.event.startTime < event2.event.startTime + event2.event.duration &&
    event1.event.startTime + event1.event.duration > event2.event.startTime
  );
}
