//utils
import axios from 'axios';
//types
import IBuddy from 'types/buddy';
import {StatusType} from 'store/status/types';
import {IEvent} from 'store/events/types';
import type {Dispatch} from 'redux';
//store
import actions from 'store/actions';

export default async function addBuddy(
  buddy: IBuddy,
  dispatch: Dispatch
): Promise<{event?: IEvent; message?: string; success?: boolean}> {
  const response = await axios.post('/add-buddy', buddy);
  const {event, message, success} = response.data;
  if (message) {
    setTimeout(
      () => dispatch(actions.status.status({message, type: event || success ? StatusType.SUCCESS : StatusType.ERROR})),
      0
    );
  }
  return {event, message, success};
}
