//utils
import axios from 'axios';
//types
import ActionType from 'store/actionTypes';

const getList =
  (params: {
    memberId: string;
    date: string;
    view: string;
    area: string;
    location: string;
    location2: string;
    regular: boolean;
    cp: boolean;
    employee: boolean;
  }): any =>
  async (dispatch: any) => {
    const response = await axios.get('/get-events', {params});
    let {events, timeToNextBookingFrame, date} = response.data;
    if (!events) {
      events = response.data;
    }

    const list = events.sort((a: any, b: any) => a.event.createdAt.localeCompare(b.event.createdAt));
    dispatch({type: ActionType.GET_EVENTS_SUCCESS, data: {list, timeToNextBookingFrame, date}});

    return list;
  };

export default getList;
