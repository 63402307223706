//types
import {StatusType} from 'store/status/types';
import {IEvent} from 'store/events/types';
import type {Dispatch} from 'redux';
//store
import actions from 'store/actions';
//utils
import axios from 'axios';

export default async function bookClass(
  eventId: string,
  eventDate: string,
  memberId: string,
  action: string,
  dispatch: Dispatch,
  attendanceId?: string
): Promise<{event: IEvent}> {
  const response = await axios.post('/book-class', {eventId, eventDate, memberId, action, attendanceId});
  const {event, message} = response.data;

  if (message) {
    dispatch(actions.status.status({message, type: StatusType.SUCCESS}));
  }
  return {event};
}
