import {useEffect, useState} from 'react';

const url = `${process.env.PUBLIC_URL}/sw.js`;

const ServiceWorkerWrapper = () => {
  const [registration, setRegistration] = useState<ServiceWorkerRegistration>();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      registerSW().then(setRegistration);
    }
  }, []);

  useEffect(() => {
    if (registration) {
      const interval = setInterval(() => {
        registration.update();
      }, 1000 * 60 * 5);
      return () => clearInterval(interval);
    }
  }, [registration]);

  return null;
};

export async function registerSW(): Promise<ServiceWorkerRegistration> {
  const registration = await navigator.serviceWorker.register(url);
  registration.onupdatefound = (): void => {
    const installingWorker = registration.installing;

    if (installingWorker == null) {
      return;
    }

    installingWorker.onstatechange = (): void => {
      if (installingWorker.state === 'installed') {
        if (navigator.serviceWorker.controller) {
          window.location.reload();
        }
      }
    };
  };

  return registration;
}

export default ServiceWorkerWrapper;
